<template>
  <main class="main">
    <section class="container">
      <BreadcrumbsComponent :title="page ? page.title : 'FAQ'" />
      <ul class="faq" v-if="faq && faq.length">
        <li v-for="(q, i) in faq" :key="i">
          <a
            href="#"
            class="faq__header"
            :class="{ active: activeFaq === i }"
            @click.prevent="activeFaq === i ? (activeFaq = null) : (activeFaq = i)"
          >
            <h2 class="faq__title">{{ q.title }}</h2>
            <FaqArrowIcon />
          </a>
          <div v-if="hasText(q.answer)" class="faq__body" v-show="activeFaq === i">
            <EditorJSComponent v-if="isJson(q.answer)" :text="JSON.parse(q.answer)" />
            <template v-else v-html="q.answer">{{ q.answer }}</template>
          </div>
        </li>
      </ul>
      <span class="faq__empty" v-else>Информация скоро появится</span>
    </section>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import FaqArrowIcon from "components/svg/FaqArrow.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import { cityIn } from "lvovich";

export default {
  name: "FaqPage",
  async asyncData({ store, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_FAQ_PAGE");
  },
  data() {
    return {
      activeFaq: null,
    };
  },
  computed: {
    page() {
      let page;
      if (this.$store.state.faq_page && this.$store.state.faq_page.page) {
        page = this.$store.state.faq_page.page;
      }
      return page;
    },
    faq() {
      if (this.$store.state.faq_page && this.$store.state.faq_page.faq) {
        return this.$store.state.faq_page.faq;
      } else {
        return [];
      }
    },
  },
  methods: {
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    hasText(text) {
      if (this.isJson(text)) {
        const parsedText = JSON.parse(text);
        return !!(parsedText && parsedText.blocks && parsedText.blocks.length);
      }
      return !!text;
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let metaName = this.page.title;
    let metaFullPath = this.$route.path;
    let alternateLink = this.$store.state.alternateLink;
    let page = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.faq_page && this.$store.state.faq_page.page) {
      page = this.$store.state.faq_page.page;
    }
    const title = (page.meta_title || page.title).replace(/{{ city }}/g, `в ${city}`);
    let img = this.getImg(page) || "";
    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              {
                "@type": "ListItem",
                position: "2",
                name: metaName,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: page.meta_keywords,
        },
        {
          vmid: "description",
          name: "description",
          content: page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: img,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
  components: {
    FaqArrowIcon,
    BreadcrumbsComponent,
    EditorJSComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/faq.styl"
</style>
