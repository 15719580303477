<template>
  <i class="faq-arrow-icon icon">
    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.49984 -5.29972e-07L6.53093 4.5L0.468751 4.5L3.49984 -5.29972e-07Z" fill="#2A3742" />
    </svg>
  </i>
</template>

<script>
export default {
  name: "FaqArrowIcon",
};
</script>
