<template>
  <div class="editor-js">
    <template v-for="(block, index) in text.blocks">
      <p :key="index" v-if="block.type === 'paragraph'" v-html="block.data.text"></p>
      <h1 :key="index" v-if="block.type === 'header' && block.data.level === 1">{{ block.data.text }}</h1>
      <h2 :key="index" v-if="block.type === 'header' && block.data.level === 2">{{ block.data.text }}</h2>
      <h3 :key="index" v-if="block.type === 'header' && block.data.level === 3">{{ block.data.text }}</h3>
      <h4 :key="index" v-if="block.type === 'header' && block.data.level === 4">{{ block.data.text }}</h4>
      <h5 :key="index" v-if="block.type === 'header' && block.data.level === 5">{{ block.data.text }}</h5>
      <h6 :key="index" v-if="block.type === 'header' && block.data.level === 6">{{ block.data.text }}</h6>
      <template v-if="block.type === 'leasing'">
        <template v-if="block.data.link">
          <a
            class="btn-dark"
            :key="index"
            v-if="block.data.type === '1'"
            target="_blank"
            rel="nofollow"
            :href="block.data.link"
            >{{ block.data.name }}</a
          >
          <a class="btn" :key="index" v-else :href="block.data.link" target="_blank" rel="nofollow">{{
            block.data.name
          }}</a>
        </template>
        <template v-else>
          <button class="btn-dark" :key="index" v-if="block.data.type === '1'" type="button">
            {{ block.data.name }}
          </button>
          <button class="btn" :key="index" v-else type="button">{{ block.data.name }}</button>
        </template>
      </template>
      <div
        :key="index"
        v-if="block.type === 'condition'"
        class="prg__conditions__list__item prg__conditions__list__item--no-grow"
      >
        <div class="prg__conditions__content">
          <span class="prg__conditions__date" v-if="block.data.from">{{ block.data.from }}</span>
          <span class="prg__conditions__number" v-if="block.data.value">{{ block.data.value }}</span>
          <span class="prg__conditions__modifier" v-if="block.data.mod">{{ block.data.mod }}</span>
          <h3 class="prg__conditions__name">{{ block.data.name }}</h3>
        </div>
        <h3 class="prg__conditions__name">{{ block.data.name }}</h3>
      </div>
      <pre :key="index" v-if="block.type === 'code'">{{ block.data.code }}</pre>
      <div :key="index" v-if="block.type === 'quote'" class="article__quote">
        <div v-html="block.data.text" class="article__quote-content"></div>
        <span>{{ block.data.caption }}</span>
      </div>
      <hr :key="index" v-if="block.type === 'delimiter'" />
      <div :key="index" v-if="block.type === 'raw'" v-html="block.data.html"></div>
      <div :key="index" v-if="block.type === 'warning'" class="article__warning">
        <span>{{ block.data.title }}</span>
        <p>{{ block.data.message }}</p>
      </div>
      <table :key="index" v-if="block.type === 'table'">
        <tbody>
          <tr v-for="(row, i) in block.data.content" :key="i">
            <td v-for="(column, j) in row" :key="j" v-html="column"></td>
          </tr>
        </tbody>
      </table>
      <ul :key="index" v-if="block.type === 'list' && block.data.style === 'unordered'">
        <li v-for="(item, i) in block.data.items" :key="i">{{ item }}</li>
      </ul>
      <ol :key="index" v-if="block.type === 'list' && block.data.style === 'ordered'">
        <li v-for="(item, i) in block.data.items" :key="i">{{ item }}</li>
      </ol>
      <a
        :key="index"
        v-if="block.type === 'linkTool'"
        :href="block.data.link"
        target="_blank"
        class="article__link"
        rel="nofollow"
      >
        <div>
          <span>{{ block.data.meta.title.length ? block.data.meta.title : block.data.link }}</span>
          <span v-if="block.data.meta.description.length">{{ block.data.meta.description }}</span>
        </div>
        <img
          v-if="block.data.meta.image.url.length"
          :src="block.data.meta.image.url"
          :alt="block.data.meta.title"
        />
      </a>
      <div
        :key="index"
        v-if="block.type === 'image'"
        class="article__image"
        :class="{
          'article__image--stretched': block.data.stretched,
          'article__image--left': block.data.left,
          'article__image--right': block.data.right,
        }"
      >
        <img
          :src="
            block.data.file.url.indexOf('http') !== -1
              ? block.data.file.url
              : $store.state.api + block.data.file.url
          "
          :alt="block.data.caption"
        />
        <span>{{ block.data.caption }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import tippy from "tippy.js";

export default {
  name: "EditorJSComponent",
  props: {
    text: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    document.querySelectorAll(".editor-js-tooltip").forEach((el) => {
      tippy(el, {
        arrow: false,
        theme: "car__tooltip",
        content: el.title,
      });
    });
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/tippy.styl"
.editor-js {
  display flex
  flex-flow row wrap
  gap 20px

  & > * {
    margin 0
  }

  ul
  ol {
    padding-left 20px
  }

  .article__quote {
    width 100%
    display flex
    flex-direction column
    border-left 5px solid main_dark
    padding 20px 0 16px 20px

    &-content {
      font-weight normal
      font-size 16px
      width 100%
      text-align left
      line-height 26px
      color main_dark
      margin-bottom 10px
    }

    span {
      font-size 14px
      line-height 26px
      text-align right
      color rgba(main_dark, 0.5)
    }
  }

  .article__link {
    display flex
    align-items flex-start
    justify-content flex-start
    box-shadow 0 15px 20px rgba(main_dark, 0.1)
    border-radius 5px
    padding 10px 15px

    > div {
      display flex
      flex-direction column

      span {
        &:first-child {
          font-size 18px
          line-height 26px
          text-align left
          color main_dark
        }

        &:last-child {
          font-size 16px
          line-height 26px
          text-align left
          color main_dark
          margin-top 10px
        }
      }
    }
  }

  .article__warning {
    display flex
    flex-direction column
    border-left 5px solid main_red
    padding 20px 0 16px 20px
    width 100%

    p {
      font-weight normal
      font-size 16px
      width 100%
      text-align left
      line-height 26px
      color main_dark
      margin 0
    }

    span {
      font-size 18px
      line-height 26px
      text-align left
      color main_red
      margin-bottom 10px
    }
  }

  .btn
  .btn-dark {
    margin 0
  }

  hr {
    margin 12px 0;
    width 100%
  }

  h1,
  h2,
  h3,
  pre,
  p {
    width 100%
  }
  +below(560px) {
    font-size: 14px;
    line-height: 22px;
  }
}
</style>
